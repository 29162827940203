import Swiper, { Navigation, Pagination } from "swiper";

Swiper.use([Navigation, Pagination]);

function init() {
	const homePage = document.querySelector(".home");
	let names = null;

	if (homePage) {
		names = homePage
			.querySelector(".lead__slider-pagination")
			.textContent.split(",");
	}

	let sliderLead;
	let section3Slider;
	let section5Slider;
	let initSliderSection3 = false;
	let initSliderSection5 = false;

	const config = {
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 1000,
	};

	const configSliderSection3 = {
		...config,
		spaceBetween: 132,
		navigation: {
			nextEl: ".home .section--3 .slider-control--next",
			prevEl: ".home .section--3 .slider-control--prev",
		},
		pagination: {
			el: ".home .slider--section3 .slider-pagination .slider-pagination-fractions",
			type: "custom",
			renderCustom: function (swiper, current, total) {
				const progressBar = document.querySelector(
					".home .slider--section3 .slider-pagination .slider-pagination-bar--inner"
				);
				progressBar.style.width = `${(current / total) * 100}%`;
				return `
					<span class="slider-pagination-fraction slider-pagination-fraction--current">${current}</span>
					<span class="slider-pagination-fraction slider-pagination-fraction--total">${total}</span>
				`;
			},
		},
	};

	const configSliderSection5 = {
		spaceBetween: 20,
		speed: 600,
	};

	const changeDarkClass = (state) => {
		if (state === 'true') {
			homePage.classList.add('home--dark');
		} else {
			homePage.classList.remove('home--dark');
		}
	};

	const setColor = (activeEl) => {
		const color = activeEl.dataset.color;
		const bg = activeEl.dataset.bg;
		const dark = activeEl.dataset.dark;

		document.documentElement.style
			.setProperty('--home-slider-color', color);
		document.documentElement.style
			.setProperty('--home-slider-bg', bg);

		changeDarkClass(dark);
	}

	function initSliderLead() {
		sliderLead = new Swiper(".home .lead__slider", {
			...config,
			autoHeight: true,
			pagination: {
				el: ".lead__slider-pagination",
				clickable: true,
				renderBullet: (index, className) => {
					return `<span class="${className}">${names[
						index
					].trim()}</span>`;
				},
			},
		});

		sliderLead.on("slideChange", (e) => {
			setColor(e.slides[e.activeIndex]);
		});
	}

	function initSlider3() {
		if (window.innerWidth > 767) {
			section3Slider = new Swiper(
				".home .slider--section3",
				configSliderSection3
			);

			initSliderSection3 = true;
		}
	}

	function initSlider5() {
		if (window.innerWidth < 767) {
			section5Slider = new Swiper(
				".home .slider--section5",
				configSliderSection5
			);

			initSliderSection5 = true;
		}
	}

	function updateSlider3() {
		if (window.innerWidth < 768) {
			if (initSliderSection3) {
				section3Slider.destroy();
				initSliderSection3 = false;
			}
		} else {
			if (!initSliderSection3) {
				section3Slider = new Swiper(
					".home .slider--section3",
					configSliderSection3
				);
				initSliderSection3 = true;
			}
		}
	}

	function updateSlider5() {
		if (window.innerWidth > 768) {
			if (initSliderSection5) {
				section5Slider.destroy();
				initSliderSection5 = false;
			}
		} else {
			if (!initSliderSection5) {
				section5Slider = new Swiper(
					".home .slider--section5",
					configSliderSection5
				);
				initSliderSection5 = true;
			}
		}
	}

	initSliderLead();
	initSlider3();
	initSlider5();

	if (homePage) {
		setColor(sliderLead.slides[0]);
	}

	window.addEventListener("resize", () => {
		updateSlider3();
		updateSlider5();
	});
}

export default {
	init,
};
