import { Form } from "./form";

const $popup = () => [...document.querySelectorAll(".popup")];
const $openButton = () => [...document.querySelectorAll(".js-popup-open")];
const $closeButton = () => [...document.querySelectorAll(".js-popup-close")];
const $html = document.querySelector("html");
export function scrollSet(state) {
    if (state) {
        $html.classList.add("is-lock-scroll");
    } else {
        $html.classList.remove("is-lock-scroll");
    }
}

export function toggle(name, state, e) {
    const $popupCurrent = document.querySelector(`.popup[data-name="${name}"]`);
    const form = e ? e.currentTarget.dataset.form : null;
    $popupCurrent.classList.toggle("is-active", state);

    if (form && state) {
        Form.checkFormState();
        Form.getForm(form, $popupCurrent);
    }
    scrollSet(state);
}

const openOnClick = (e) => {
    toggle(e.currentTarget.dataset.popup, true, e);
};

const closeOnClick = (e) => {
    toggle(e.currentTarget.dataset.popup, false, e);
};

const closeOnPopupClick = (e) => {
    if (!e.target.classList.contains("popup__container") &&
        e.target.closest(".popup__container") === null
    ) {
        toggle(e.target.closest(".popup").dataset.name, false, e);
    }
};

const closeOnKeydown = (e) => {
    if (
        document.querySelector(".popup.is-active") !== null &&
        e.keyCode === 27
    ) {
        [...document.querySelectorAll(".popup.is-active")].forEach(
            ($popupCurrent) => {
                toggle($popupCurrent.dataset.name, false);
            }
        );
    }
};

const destroy = () => {
    if (!$popup().length) {
        return;
    }

    $popup().forEach(($popupCurrent) => {
        $popupCurrent.removeEventListener("click", closeOnPopupClick, false);

        toggle($popupCurrent.dataset.name, false);
    });

    $openButton().forEach(($openButtonCurrent) => {
        $openButtonCurrent.removeEventListener("click", openOnClick, false);
    });

    $closeButton().forEach(($closeButtonCurrent) => {
        $closeButtonCurrent.removeEventListener("click", closeOnClick, false);
    });

    document.removeEventListener("keydown", closeOnKeydown, false);
};

const init = () => {
    if (!$popup().length) {
        return;
    }
    $popup().forEach(($popupCurrent) => {
        $popupCurrent.addEventListener("click", closeOnPopupClick, false);
    });

    $openButton().forEach(($openButtonCurrent) => {
        $openButtonCurrent.addEventListener("click", openOnClick, false);
    });

    $closeButton().forEach(($closeButtonCurrent) => {
        $closeButtonCurrent.addEventListener("click", closeOnClick, false);
    });

    document.addEventListener("keydown", closeOnKeydown, false);
};

const update = () => {
    destroy();
    init();
};

export default {
    init,
    destroy,
    update,
    toggle,
};