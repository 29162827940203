function init() {
	const allBtns = document.querySelectorAll(".dropdown-btn");

	function update() {
		allBtns.forEach((el) => {
			const parrent = el.closest(".dropdown");
			const content = parrent.querySelector(".dropdown-content");
			const wrapper = parrent.querySelector(".dropdown-wrapper");

			if (content.clientHeight <= wrapper.clientHeight) {
				el.style.display = "none";
				wrapper.classList.add('dropdown-wrapper--disable')
			}
		});
	}

	allBtns.forEach((el) => {
		el.addEventListener("click", (e) => {
			const parrent = e.currentTarget.closest(".dropdown");
			const content = parrent.querySelector(".dropdown-content");
			const wrapper = parrent.querySelector(".dropdown-wrapper");

			e.currentTarget.classList.toggle("active");
			wrapper.classList.toggle("active");

			if (wrapper.classList.contains("active")) {
				const heightContent = content.clientHeight;
				wrapper.style.height = `${heightContent}px`;
			} else {
				wrapper.style.height = 0;
			}
		});
	});

	update();

	window.addEventListener('resize', update);

}

export default {
	init,
};
