import { isMobile, throttle } from "./helpers/helpers";
import { scrollSet } from "./popup";

let header;
let burger;
let pause = false;
let currentCrollPos = 0;

function close() {
	header.classList.remove("menu-open");
	burger.classList.remove("active");
	scrollSet(false);
}

function toggleFix() {
	const scroll = window.pageYOffset;
	
	if ((scroll < window.innerHeight && !header.classList.contains('header--fixed')) || scroll === 0) {
		header.classList.remove('header--fixed');
		header.classList.remove('header--hide');
	} else if (scroll > currentCrollPos && scroll > window.innerHeight) {
		header.classList.remove('header--fixed');
		header.classList.add('header--hide');
	} else if (scroll < currentCrollPos && scroll > window.innerHeight) {
		header.classList.add('header--fixed');
		header.classList.remove('header--hide');
	}

	currentCrollPos = scroll;
}

function toggle() {
	if (!pause) {
		if (!header.classList.contains("menu-open")) {
			header.classList.add("menu-open");
			burger.classList.add("active");
			scrollSet(true);
		} else {
			close();
		}

		pause = true;
		setTimeout(() => {
			pause = false;
		}, 500);
	}
}

const setFixPosition = throttle(toggleFix, 500);

function init() {
	header = document.querySelector(".header");
	burger = document.querySelector(".js-burger");

	burger.addEventListener("click", toggle);

	window.addEventListener('scroll', () => {
		if (isMobile()) {
			setFixPosition();
		}
	})
}

export default {
	init,
	toggle,
	close,
	header,
};
