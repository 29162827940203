// import map from './components/map';
// import popup from './components/popup';
// import setForms from './components/setForms';
import lazyBlure from "./components/lazyBlur";
import header from "./components/header";
import slider from "./components/slider";
import dropdown from "./components/dropdown";
import scrollToAnchor from './components/scrollToAnchor';
import Aos from "aos";

document.addEventListener("DOMContentLoaded", () => {
	Aos.init({
		duration: 1000,
        offset: -60,
		once: true,
	});
	header.init();
	lazyBlure.init();
	// setForms.init();
	// map.init();
	// popup.init();
	slider.init();
	dropdown.init();
	scrollToAnchor.init();
});
